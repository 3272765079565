import { Box, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo/Seo";

const CookiesPage = () => {
  return (
    <Layout>
      <Seo />

      <Box
        pb="144px"
        pt={{ base: "120px", lg: "204px" }}
        overflow="hidden"
        width="100%"
        gridColumn="1/-1"
        position="relative"
      >
        <Stack
          opacity="1"
          display="flex"
          alignItems="start"
          flex="0 1 0%"
          maxWidth={{ base: "unset", xl: "1206px" }}
          px={{ base: "6", md: "10", lg: "20", "3xl": "0" }}
          margin="0px auto"
          spacing="1"
        >
          <p class="c10">
            <Heading as="h1" fontSize={{ base: "28px", md: "36px" }}>
              COOKIE POLICY
            </Heading>
          </p>
          <p class="c10 c18">
            <span class="c1"></span>
          </p>
          <p class="c10">
            <span class="c14 c6 c19">Last updated January 25, 2022</span>
          </p>
          <p class="c10 c18">
            <span class="c1"></span>
          </p>
          <p class="c10 c18">
            <span class="c1"></span>
          </p>
          <p class="c10 c18">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c7 c6">
              This Cookie Policy explains how Motarme Limited (&quot;
            </span>
            <span class="c7 c14 c6">Company</span>
            <span class="c6 c7">&quot;, &quot;</span>
            <span class="c7 c14 c6">we</span>
            <span class="c7 c6">&quot;, &quot;</span>
            <span class="c7 c14 c6">us</span>
            <span class="c7 c6">&quot;, and &quot;</span>
            <span class="c7 c6 c14">our</span>
            <span class="c7 c6">
              &quot;) uses cookies and similar technologies to recognize you
              when you visit our websites at{" "}
            </span>
            <span class="c3">
              <a
                class="c12"
                href="https://www.google.com/url?q=https://motar.me/&amp;sa=D&amp;source=editors&amp;ust=1643974445950415&amp;usg=AOvVaw24WoFX4N9HKw4YMXr6hCmV"
              >
                https://motar.me/
              </a>
            </span>
            <span class="c7 c6">, (&quot;</span>
            <span class="c7 c14 c6">Websites</span>
            <span class="c4">
              &quot;). It explains what these technologies are and why we use
              them, as well as your rights to control our use of them.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              In some cases we may use cookies to collect personal information,
              or that becomes personal information if we combine it with other
              information.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
              What are cookies?
            </Heading>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              Cookies are small data files that are placed on your computer or
              mobile device when you visit a website. Cookies are widely used by
              website owners in order to make their websites work, or to work
              more efficiently, as well as to provide reporting information.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              Cookies set by the website owner (in this case, Motarme Limited)
              are called &quot;first party cookies&quot;. Cookies set by parties
              other than the website owner are called &quot;third party
              cookies&quot;. Third party cookies enable third party features or
              functionality to be provided on or through the website (e.g. like
              advertising, interactive content and analytics). The parties that
              set these third party cookies can recognize your computer both
              when it visits the website in question and also when it visits
              certain other websites.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
              Why do we use cookies?
            </Heading>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              We use first and third party cookies for several reasons. Some
              cookies are required for technical reasons in order for our
              Websites to operate, and we refer to these as
              &quot;essential&quot; or &quot;strictly necessary&quot; cookies.
              Other cookies also enable us to track and target the interests of
              our users to enhance the experience on our Online Properties.
              Third parties serve cookies through our Websites for advertising,
              analytics and other purposes. This is described in more detail
              below.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              The specific types of first and third party cookies served through
              our Websites and the purposes they perform are described below
              (please note that the specific cookies served may vary depending
              on the specific Online Properties you visit):
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c14 c6 c17">How can I control cookies?</span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              You have the right to decide whether to accept or reject cookies.
              You can exercise your cookie rights by setting your preferences in
              the Cookie Consent Manager. The Cookie Consent Manager allows you
              to select which categories of cookies you accept or reject.
              Essential cookies cannot be rejected as they are strictly
              necessary to provide you with services.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              The Cookie Consent Manager can be found in the notification banner
              and on our website. If you choose to reject cookies, you may still
              use our website though your access to some functionality and areas
              of our website may be restricted. You may also set or amend your
              web browser controls to accept or refuse cookies. As the means by
              which you can refuse cookies through your web browser controls
              vary from browser-to-browser, you should visit your browser&#39;s
              help menu for more information.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c7 c6">
              In addition, most advertising networks offer you a way to opt out
              of targeted advertising. If you would like to find out more
              information, please visit{" "}
            </span>
            <span class="c3">
              <a
                class="c12"
                href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;source=editors&amp;ust=1643974445953145&amp;usg=AOvVaw2dMh85bcgiVZentEvTlMB0"
              >
                http://www.aboutads.info/choices/
              </a>
            </span>
            <span class="c7 c6">&nbsp;or </span>
            <span class="c3">
              <a
                class="c12"
                href="https://www.google.com/url?q=http://www.youronlinechoices.com&amp;sa=D&amp;source=editors&amp;ust=1643974445953362&amp;usg=AOvVaw2qKVY1C8aWZ-NlleLK4790"
              >
                http://www.youronlinechoices.com
              </a>
            </span>
            <span class="c4">.</span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              The specific types of first and third party cookies served through
              our Websites and the purposes they perform are described in the
              table below (please note that the specific cookies served may vary
              depending on the specific Online Properties you visit):
            </span>
          </p>
          <p class="c0">
            <span class="c14 c6 c24"></span>
          </p>
          <p class="c8">
            <span class="c24 c14 c6">Essential website cookies:</span>
          </p>
          <p class="c10 c18">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              These cookies are strictly necessary to provide you with services
              available through our Websites and to use some of its features,
              such as access to secure areas.
            </span>
          </p>

          <table class="c21">
            <tbody>
              <tr class="c22">
                <td class="c9" colspan="1" rowspan="1">
                  <p class="c15">
                    <span class="c11">Name:</span>
                  </p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c10">
                    <span class="c2">__tlbcpv</span>
                  </p>
                </td>
              </tr>
              <tr class="c22">
                <td class="c9" colspan="1" rowspan="1">
                  <p class="c15">
                    <span class="c11">Purpose:</span>
                  </p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c10">
                    <span class="c2">
                      Used to record unique visitor views of the consent banner.
                    </span>
                  </p>
                </td>
              </tr>
              <tr class="c22">
                <td class="c9" colspan="1" rowspan="1">
                  <p class="c15">
                    <span class="c11">Provider:</span>
                  </p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c10">
                    <span class="c2">.termly.io</span>
                  </p>
                </td>
              </tr>
              <tr class="c22">
                <td class="c9" colspan="1" rowspan="1">
                  <p class="c15">
                    <span class="c11">Service:</span>
                  </p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c10">
                    <span class="c2">Termly </span>
                    <span class="c20">
                      <a
                        class="c12"
                        href="https://www.google.com/url?q=https://termly.io/our-privacy-policy/&amp;sa=D&amp;source=editors&amp;ust=1643974445959593&amp;usg=AOvVaw3JA6QF_h-KbKXf3QeraJUF"
                      >
                        View Service Privacy Policy
                      </a>
                    </span>
                    <span class="c2">&nbsp; </span>
                  </p>
                </td>
              </tr>
              <tr class="c22">
                <td class="c9" colspan="1" rowspan="1">
                  <p class="c15">
                    <span class="c11">Country:</span>
                  </p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c10">
                    <span class="c2">United States</span>
                  </p>
                </td>
              </tr>
              <tr class="c22">
                <td class="c9" colspan="1" rowspan="1">
                  <p class="c15">
                    <span class="c11">Type:</span>
                  </p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c10">
                    <span class="c2">http_cookie</span>
                  </p>
                </td>
              </tr>
              <tr class="c22">
                <td class="c9" colspan="1" rowspan="1">
                  <p class="c15">
                    <span class="c11">Expires in:</span>
                  </p>
                </td>
                <td class="c5" colspan="1" rowspan="1">
                  <p class="c10">
                    <span class="c2">1 year</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
              What about other tracking technologies, like web beacons?
            </Heading>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              Cookies are not the only way to recognize or track visitors to a
              website. We may use other, similar technologies from time to time,
              like web beacons (sometimes called &quot;tracking pixels&quot; or
              &quot;clear gifs&quot;). These are tiny graphics files that
              contain a unique identifier that enable us to recognize when
              someone has visited our Websites or opened an e-mail including
              them. This allows us, for example, to monitor the traffic patterns
              of users from one page within a website to another, to deliver or
              communicate with cookies, to understand whether you have come to
              the website from an online advertisement displayed on a
              third-party website, to improve site performance, and to measure
              the success of e-mail marketing campaigns. In many instances,
              these technologies are reliant on cookies to function properly,
              and so declining cookies will impair their functioning.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
              Do you use Flash cookies or Local Shared Objects?
            </Heading>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              Websites may also use so-called &quot;Flash Cookies&quot; (also
              known as Local Shared Objects or &quot;LSOs&quot;) to, among other
              things, collect and store information about your use of our
              services, fraud prevention and for other site operations.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c7 c6">
              If you do not want Flash Cookies stored on your computer, you can
              adjust the settings of your Flash player to block Flash Cookies
              storage using the tools contained in the{" "}
            </span>
            <span class="c3">
              <a
                class="c12"
                href="https://www.google.com/url?q=http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html&amp;sa=D&amp;source=editors&amp;ust=1643974445963584&amp;usg=AOvVaw2IhXIbQGJ9IykQYUlqzRbI"
              >
                Website Storage Settings Panel
              </a>
            </span>
            <span class="c6">
              . You can also control Flash Cookies by going to the{" "}
            </span>
            <span class="c3">
              <a
                class="c12"
                href="https://www.google.com/url?q=http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html&amp;sa=D&amp;source=editors&amp;ust=1643974445963846&amp;usg=AOvVaw3QPDShMFeltmw_OODOemxj"
              >
                Global Storage Settings Panel
              </a>
            </span>
            <span class="c4">
              &nbsp;and following the instructions (which may include
              instructions that explain, for example, how to delete existing
              Flash Cookies (referred to &quot;information&quot; on the
              Macromedia site), how to prevent Flash LSOs from being placed on
              your computer without your being asked, and (for Flash Player 8
              and later) how to block Flash Cookies that are not being delivered
              by the operator of the page you are on at the time).
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              Please note that setting the Flash Player to restrict or limit
              acceptance of Flash Cookies may reduce or impede the functionality
              of some Flash applications, including, potentially, Flash
              applications used in connection with our services or online
              content.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
              Do you serve targeted advertising?
            </Heading>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              Third parties may serve cookies on your computer or mobile device
              to serve advertising through our Websites. These companies may use
              information about your visits to this and other websites in order
              to provide relevant advertisements about goods and services that
              you may be interested in. They may also employ technology that is
              used to measure the effectiveness of advertisements. This can be
              accomplished by them using cookies or web beacons to collect
              information about your visits to this and other sites in order to
              provide relevant advertisements about goods and services of
              potential interest to you. The information collected through this
              process does not enable us or them to identify your name, contact
              details or other details that directly identify you unless you
              choose to provide these.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
              How often will you update this Cookie Policy?
            </Heading>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              We may update this Cookie Policy from time to time in order to
              reflect, for example, changes to the cookies we use or for other
              operational, legal or regulatory reasons. Please therefore
              re-visit this Cookie Policy regularly to stay informed about our
              use of cookies and related technologies.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              The date at the top of this Cookie Policy indicates when it was
              last updated.
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <Heading as="h2" fontSize={{ base: "20px", md: "24px" }}>
              Where can I get further information?
            </Heading>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">
              If you have any questions about our use of cookies or other
              technologies, please email us at privacy@motarme.com or by post
              to:
            </span>
          </p>
          <p class="c0">
            <span class="c1"></span>
          </p>
          <p class="c8">
            <span class="c4">Motarme Limited</span>
          </p>
          <p class="c8">
            <span class="c4">Pembroke Hall</span>
          </p>
          <p class="c8">
            <span class="c6 c16">Dublin</span>
          </p>
          <p class="c8">
            <span class="c16 c6">Ireland</span>
          </p>
          <p class="c8">
            <span class="c6">Phone: +353 1 969 5029</span>
          </p>
          <p class="c10 c18">
            <span class="c1"></span>
          </p>
        </Stack>
      </Box>
    </Layout>
  );
};

export default CookiesPage;
